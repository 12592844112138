<template>
  <NavBar :title="'优惠券信息'"></NavBar>
  <div class="content">
    <div class="items">
      <div class="item">
        <div class="type">优惠券</div>
        <div class="prod">
          <div class="card">
            <span class="title">{{ item.coupon[0].title }}</span>
            <span class="desc">{{ item.coupon[0].desc }}</span>
            <span class="time">有效期至 {{ item.coupon[0].end_date }}</span>
          </div>
          <div class="money-info">
            <div class="money">
              <span class="symbol">¥</span>
              <span class="number">{{ item.coupon[0].money }}</span>
            </div>
            <span class="where">*满{{ item.coupon[0].full_money }}可用</span>
          </div>
        </div>
        <div class="remarks" v-if="item.is_overdue === '1'">
          <span class="use">进行中</span>
        </div>
        <div class="remarks" v-else>
          <span class="use">已结束</span>
        </div>
      </div>
    </div>
    <van-cell-group>
      <van-cell title="活动详情" is-link to="activityDetails" />
      <van-cell title="核销记录" is-link to="verificationList" />
      <van-cell title="核销员管理" is-link to="verification" />
      <van-cell title="活动数据" is-link to="activityData"
    /></van-cell-group>
    <div>
      <button class="finish">结束活动</button>
    </div>
  </div>
</template>

<script>
import { Cell, CellGroup } from 'vant'

export default {
  name: 'detailsInfo',
  components: {
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
  },
  data() {
    return {
      item: this.$state.activity,
    }
  },
  methods: {
    finish() {
      this.$notify({ type: 'success', message: '完成啦!' })
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 10px;
  // line-height: 44px;
}
.content {
  padding: 0 32px;
  position: relative;
  .finish {
    width: 311px;
    height: 44px;
    background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
    border-radius: 8px 8px 8px 8px;
    border: none;
    position: absolute;
    bottom: -145px;
    color: #fff;
  }
  .items {
    padding: 8px 0;
    .item {
      // height: 120px;
      background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
      border-radius: 16px 16px 16px 16px;
      margin: 8px 0;
      padding: 5px;
      position: relative;
      .type {
        width: 39px;
        height: 15px;
        font-size: 13px;
        font-weight: 800;
        right: 2px;
        color: #ffffff;
        line-height: 15px;
        position: absolute;
        border: 2px solid #fff;
        transform: rotate(25deg);
        z-index: 11;
        background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
        top: 10px;
      }
      .prod {
        margin: 0 auto;
        height: 87px;
        display: flex;
        background: #ffffff;
        border-radius: 12px 12px 12px 12px;
        // padding-top: 5px;
        .money-info {
          // margin-right: 10px;
          position: absolute;
          line-height: 60px;
          right: 24px;
          .money {
            height: 60px;
            font-weight: bold;
            color: #242426;
            .symbol {
              font-size: 25px;
            }
            .number {
              font-size: 55px;
            }
          }

          .where {
            display: block;
            text-align: right;
            font-size: 10px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 12px;
            padding-right: 4px;
          }
        }
        .card {
          padding-top: 13px;
          padding-left: 14px;
          .title {
            width: 160px;
            text-align: left;
            display: block;
            height: 24px;
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .desc {
            width: 160px;
            text-align: left;
            display: block;
            height: 20px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .time {
            width: 160px;
            text-align: left;
            display: block;
            height: 20px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .remarks {
        height: 33px;
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        position: relative;
        text {
          line-height: 33px;
        }
        .desc {
          position: absolute;
          left: 14px;
        }
        .use {
          position: absolute;
          right: 24px;
          width: 61px;
          height: 19px;
          line-height: 19px;
          background: #ffffff;
          color: #f56b4e;
          border-radius: 51px 51px 51px 51px;
          top: 7px;
          text-align: center;
        }
      }
      .tip {
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        padding: 5px 24px 10px 14px;
      }
    }
  }
}
</style>
